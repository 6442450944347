import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Header from "../components/header"
import { Content, Intro, Section } from "../components/primitives"
import { PageNotFoundQuery } from "../../graphql-types"

export const PageNotFound: React.FC<{ data: PageNotFoundQuery }> = ({
  data,
}) => {
  return (
    <>
      <Header title="404" />
      <Section
        css={`
          background: ${props => props.theme.colors.sectionBackground};
        `}
      >
        <Intro>
          <div>
            {data.file?.childImageSharp && (
              <Img fixed={data.file?.childImageSharp.fixed} />
            )}
            <h2>Pagina niet gevonden</h2>
          </div>
          <Content>
            <p>Deze pagina is niet gevonden of heeft nooit bestaan.</p>
            <p>
              <a href="/">Terug naar de homepage.</a>
            </p>
          </Content>
        </Intro>
      </Section>
    </>
  )
}

export const query = graphql`
  query PageNotFound {
    file(relativePath: { eq: "beeldmerk-black.png" }) {
      childImageSharp {
        fixed(width: 100, height: 245, webpQuality: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`

export default PageNotFound
